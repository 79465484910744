import Rec from "../img/side-rec.svg";
import AboutBg from "../img/bg-blocks.png";


const Valorem = () => {
    return (
        <div id="anc-about" className="sec-valorem-home dpf-ccc">
            <div className="div-valorem-home dpf-rsc">
                <div className="div-val-left dpf-css">
                    <div className="title-box-val dpf-css">
                        {/*<img src={Rec} className="side-rec"/>*/}
                        <h2 className="title-h2 val0">WE ARE</h2>
                        <h2 className="title-h2 val">VALOREM</h2>
                    </div>
                    <div className="val-scroll">
                        <div className="line-div">
                            <div className="mouse-line"></div>
                            <div className="arrow-down"></div>
                        </div>
                        <div className="mouse-right">
                            <div className="mouse-border">
                                <div className="mouse-circle"></div>
                            </div>
                            <p className="mouse-scroll-txt">Scroll<br/>Down</p>
                        </div>
                    </div>
                </div>
                <div className="div-val-right">
                    <div className="val-txt">
                        <p className="valorem-txt">
                            Valorem Partners berfokus pada industri blockchain di Indonesia. Konsultan ahli dan mitra kami di berbagai sektor industri mampu dan siap untuk memberikan solusi optimal untuk anda.
                            <br/>
                            <br/>
                            Berdiri pada tahun 2018, hingga saat ini tim yang terdiri lebih dari 150 individu berbakat dan berdedikasi tinggi serta segudang pengalaman, karenanya kami tidak hanya meningkatkan kemampuan kami namun juga memperluas area bisnis kami.
                        </p>
                        <div className="about-left-bot">
                            <div className="about-line"></div>
                            <p className="about-val">THE VALOREM PARTNERS | 2022</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Valorem